import React, { useState } from "react";
import styles from "./ngoAwardsPartnersSection.module.scss";
import Picture from "./picture";

const NgoAwardsPartnersSection = ({ awards, partners, type }) => {
  let [awardsSlideIndex, setAwardsSlideIndex] = useState(1);
  let [distirbutorSlideIndex, setDistirbutorSlideIndex] = useState(1);
  const dataArray = type === "awards" ? awards : partners;

  const handleCarouselClick = (e, element) => {
    const carouselItem = document.getElementById(element);
    if (!carouselItem) return;
    if (carouselItem.scrollIntoViewIfNeeded) {
      e.preventDefault();
      carouselItem.scrollIntoViewIfNeeded();
    } else if (carouselItem.scrollIntoView) {
      e.preventDefault();
      carouselItem.scrollIntoView();
    }
  };

  function slideToPreviousOrNextNews(next, previous, event, type) {
    if (type === "award") {
      if (next === true && awardsSlideIndex + 1 <= awards.length) {
        setAwardsSlideIndex(++awardsSlideIndex);
        handleCarouselClick(event, `award-slide-${awardsSlideIndex}`);
      } else if (previous === true && awardsSlideIndex - 1 >= 1) {
        setAwardsSlideIndex(--awardsSlideIndex);
        handleCarouselClick(event, `award-slide-${awardsSlideIndex}`);
      } else {
        event.preventDefault();
      }
    } else if (type === "distributor") {
      if (next === true && distirbutorSlideIndex + 1 <= partners.length) {
        setDistirbutorSlideIndex(++distirbutorSlideIndex);
        handleCarouselClick(
          event,
          `distributor-slide-${distirbutorSlideIndex}`
        );
      } else if (previous === true && distirbutorSlideIndex - 1 >= 1) {
        setDistirbutorSlideIndex(--distirbutorSlideIndex);
        handleCarouselClick(
          event,
          `distributor-slide-${distirbutorSlideIndex}`
        );
      } else {
        event.preventDefault();
      }
    }
  }

  return (
    <section
      className={`col-12 ${styles.awardsSection} bgF7FBF2 ${styles.sectionPadding}`}
    >
      <span className="pill-label pill-bg-gradient">
        {type === "awards" ? "Social Significance" : "partners"}
      </span>
      <h1 className="section-title text-black">
        {type === "awards" ? "Awards Received" : "Implementation Partners"}
      </h1>
      <div className={`carousel d-flex`}>
        {dataArray.map((x, index) => {
          return (
            <div
              id={`${type === "awards" ? "award" : "distributor"}-slide-${
                index + 1
              }`}
              className={type === "awards" ? styles.award : styles.distributor}
              key={index}
            >
              <div className={`${styles.imageContainer} position-relative`}>
                {x.fluid.src && (
                  <div className="position-relative">
                    <Picture
                      image={x}
                      className={`${styles.cardBackgroundImage} position-absolute abs-top-left w-100 h-100`}
                    />
                    <div className="position-relative">
                      <Picture
                        image={x}
                        className={
                          type === "awards"
                            ? styles.awardImage
                            : styles.distributorImage
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              {type === "partners" && (
                <div className={`${styles.nameDesignationContainer} mt-2`}>
                  <p
                    className={`text-center font-20 text-637285 letterSpacing0 mb-0`}
                  >
                    {x.title}
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="arrowCarouselControl w-100 d-flex justify-content-center align-items-center mt-3">
        <a
          href={type === "awards" ? "#award-slide-1" : "#distributor-slide-1"}
          className="mr-2 h-100"
          onClick={e => {
            slideToPreviousOrNextNews(
              false,
              true,
              e,
              type === "awards" ? "award" : "distributor"
            );
          }}
        >
          <img
            src="/icons/arrow-left.svg"
            className="navigationArrow cursor-pointer"
            alt="Arrow Left"
          />
        </a>
        <span className="slidesCountInfo font-20 text-0066A2 lineHeight1">
          Showing {type === "awards" ? awardsSlideIndex : distirbutorSlideIndex}{" "}
          of {dataArray.length}
        </span>
        <a
          href={
            type === "awards"
              ? `#award-slide-${dataArray.length}`
              : `#distributor-slide-${dataArray.length}`
          }
          className="ml-2 h-100"
          onClick={e => {
            slideToPreviousOrNextNews(
              true,
              false,
              e,
              type === "awards" ? "award" : "distributor"
            );
          }}
        >
          <img
            src="/icons/arrow-right.svg"
            className="navigationArrow cursor-pointer"
            alt="Arrow Right"
          />
        </a>
      </div>
    </section>
  );
};

export default NgoAwardsPartnersSection;
