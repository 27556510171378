import React from "react";
import iconMisssion from "../assets/icn-mission.svg";
import iconVision from "../assets/icn-vision.svg";
import Picture from "./picture";
import styles from "./ngoMissionSection.module.scss";

const NgoMissionSection = ({
  Ngomissionimg,
  displayBeneficiariesCount,
  beneficiariesCount,
  mission,
  vision,
}) => {
  return (
    <section className={`col-12 bgF7FBF2 section-padding my-0`}>
      <div
        className={`d-flex ${styles.missionVisionContainer} ${styles.sectionPadding}`}
      >
        {Ngomissionimg && (
          <Picture
            image={Ngomissionimg}
            className={`${styles.missionVisionImage} d-none d-lg-block`}
          />
        )}
        <div className={`${styles.missionVisionDetails} w-100`}>
          <span
            className={`pill-label pill-bg-gradient ${styles.missionVisionLabel}`}
          >
            mission & vision
          </span>
          {displayBeneficiariesCount === true && (
            <h1
              className={`section-title text-black ${styles.missionVisionTitle}`}
            >
              Over {beneficiariesCount}+ Beneficiaries
            </h1>
          )}
          <div
            className={`${styles.missionVisionTextContainer} d-flex align-items-center`}
          >
            <img
              src={iconMisssion}
              className={styles.missionVissionIcon}
              alt="Mission icon"
            />
            <div>
              <h3 className={styles.missionVisionTitleLabel}>Our Mission</h3>
              <p className={`${styles.missionVisionText} mb-0`}>{mission}</p>
            </div>
          </div>
          <div
            className={`${styles.missionVisionTextContainer} d-flex align-items-centerx`}
          >
            <img
              src={iconVision}
              className={styles.missionVissionIcon}
              alt={"Vision icon"}
            />
            <div>
              <h3 className={styles.missionVisionTitleLabel}>Our Vision</h3>
              <p className={`${styles.missionVisionText} mb-0`}>{vision}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NgoMissionSection;
