import React from "react";

const NgoAboutSection = ({ ngoname, description }) => {
  return (
    <section className={`col-12 my-5 py-5 section-padding`}>
      <span className="pill-label pill-bg-gradient">About NGO</span>
      <h1 className="section-title text-black">About {ngoname}</h1>
      <div className={`text-center font-20 text-637285 letterSpacing0`}>
        <p className="mb-0">{description.description}</p>
      </div>
    </section>
  );
};

export default NgoAboutSection;
