import React, { useState } from "react";
import styles from "./ngoTrusteeSection.module.scss";
import Picture from "./picture";

const NgoTrusteeSection = ({ trusteeImage }) => {
  let [trusteeSlideIndex, setTrusteeSlideIndex] = useState(1);

  const handleCarouselClick = (e, element) => {
    const carouselItem = document.getElementById(element);
    if (!carouselItem) return;
    if (carouselItem.scrollIntoViewIfNeeded) {
      e.preventDefault();
      carouselItem.scrollIntoViewIfNeeded();
    } else if (carouselItem.scrollIntoView) {
      e.preventDefault();
      carouselItem.scrollIntoView();
    }
  };

  function slideToPreviousOrNextNews(next, previous, event, type) {
    if (type === "trustee") {
      if (next === true && trusteeSlideIndex + 1 <= trusteeImage.length) {
        setTrusteeSlideIndex(++trusteeSlideIndex);
        handleCarouselClick(event, `trustee-slide-${trusteeSlideIndex}`);
      } else if (previous === true && trusteeSlideIndex - 1 >= 1) {
        setTrusteeSlideIndex(--trusteeSlideIndex);
        handleCarouselClick(event, `trustee-slide-${trusteeSlideIndex}`);
      } else {
        event.preventDefault();
      }
    }
  }

  return (
    <section className={`col-12 ${styles.leadershipSection} w-100`}>
      <span className="pill-label pill-bg-gradient">Leadership</span>
      <h1 className="section-title text-black">Board of Trustees</h1>
      <div className={`carousel d-flex`}>
        {trusteeImage.map((x, index) => {
          return (
            <div
              id={`trustee-slide-${index + 1}`}
              className={styles.trustee}
              key={index}
            >
              <div className={styles.imageContainer}>
                {x.fluid.src && (
                  <div className={styles.pictureContainer}>
                    <div className="position-relative">
                      <Picture image={x} className={styles.trusteeImage} />
                    </div>
                  </div>
                )}
              </div>
              <div className={`${styles.nameDesignationContainer}`}>
                <h3 className={`text-center mb-0 font-20 text-102623`}>
                  {x.title}
                </h3>
                <p className={`text-center mb-0 font-16 text-798585`}>
                  {x.description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="arrowCarouselControl w-100 d-flex justify-content-center align-items-center mt-3">
        <a
          href="#trustee-slide-1"
          className="mr-2 h-100"
          onClick={e => {
            slideToPreviousOrNextNews(false, true, e, "trustee");
          }}
        >
          <img
            src="/icons/arrow-left.svg"
            className="navigationArrow cursor-pointer"
            alt="Arrow Left"
          />
        </a>
        <span className="slidesCountInfo font-20 text-0066A2 lineHeight1">
          Showing {trusteeSlideIndex} of {trusteeImage.length}
        </span>
        <a
          href="#trustee-slide-5"
          className="ml-2 h-100"
          onClick={e => {
            slideToPreviousOrNextNews(true, false, e, "trustee");
          }}
        >
          <img
            src="/icons/arrow-right.svg"
            className="navigationArrow cursor-pointer"
            alt="Arrow Right"
          />
        </a>
      </div>
    </section>
  );
};

export default NgoTrusteeSection;
