import React from "react";
import Picture from "./picture";
import styles from "./ngoCertificatesSection.module.scss";

const NgoCertificatesSection = ({ certificates }) => {
  return (
    <section className={`col-12 ${styles.certificatesSection}`}>
      <span className="pill-label pill-bg-gradient">Legal significance</span>
      <h1 className="section-title text-black">Certificates</h1>
      <div
        className={`d-flex justify-content-center justify-content-lg-around flex-wrap`}
      >
        {certificates.map((certificate, index) => {
          return (
            <div className={styles.certificateCard} key={index}>
              <div className={`mb-2`}>
                <div>
                  <div className="position-relative">
                    <Picture
                      image={certificate}
                      className={styles.certificate}
                    />
                  </div>
                </div>
              </div>
              <p
                className={`text-637285 mb-5 text-center letterSpacing0 font-22 font-weight-bolder`}
              >
                {certificate.title}
              </p>
            </div>
          );
        })}
        {certificates.map((certificate, index) => {
          return (
            <div className={styles.certificateCard} key={index}>
              <div className={`mb-2`}>
                <div>
                  <div className="position-relative">
                    <Picture
                      image={certificate}
                      className={styles.certificate}
                    />
                  </div>
                </div>
              </div>
              <p
                className={`text-637285 mb-5 text-center letterSpacing0 font-22 font-weight-bolder`}
              >
                {certificate.title}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default NgoCertificatesSection;
