import React, { useEffect, useCallback, useRef } from "react";
import signVerified from "../assets/sign-verified.svg";
import Picture from "./picture";
import styles from "./ngoHeroSection.module.scss";

const NgoHeroSection = ({ bannercontent }) => {
  let activeIndex = useRef(0);
  let indicatorClicked = false;
  let carouselInterval = useRef(null);

  const setActiveIndex = useCallback(totalCarouselItemCount => {
    const indicatorContainer = document.getElementsByClassName(
      styles.carouselControl
    );
    const atags = indicatorContainer[0].children;
    if (activeIndex.current + 1 < totalCarouselItemCount) {
      atags[activeIndex.current].classList.remove(styles.active);
      activeIndex.current++;
      atags[activeIndex.current].classList.add(styles.active);
    } else {
      atags[activeIndex.current].classList.remove(styles.active);
      activeIndex.current = 0;
      atags[activeIndex.current].classList.add(styles.active);
    }
  }, []);
  const nextBanner = useCallback(() => {
    const carousel = document.getElementById("banner-carousel");
    if (carousel) {
      const carouselItems = document.getElementsByClassName(
        styles.carouselItem
      );

      if (carouselItems.length > 1) {
        const itemWidth = carouselItems[0].clientWidth;
        let calculatedItemWidth = 0;

        if (itemWidth * carouselItems.length !== carousel.scrollWidth) {
          calculatedItemWidth = carousel.scrollWidth / carouselItems.length;
        }

        const ItemWidth =
          calculatedItemWidth === 0 ? itemWidth : calculatedItemWidth;

        if (
          carousel.scrollLeft < carousel.scrollWidth - ItemWidth &&
          carousel.scrollLeft <
            carousel.scrollWidth - ItemWidth - carousel.scrollWidth * 0.05
        )
          carousel.scrollBy({ left: itemWidth, top: 0, behavior: "smooth" });
        else {
          carousel.scrollTo({ left: 0, top: 0, behavior: "smooth" });
        }
        setActiveIndex(carouselItems.length);
      }
    }
  }, [setActiveIndex]);
  const runHeroCarousel = useCallback(() => {
    if (bannercontent.bannerimg.length > 1) {
      carouselInterval.current = setInterval(() => {
        if (indicatorClicked === false) {
          nextBanner();
        }
      }, 12000);
    }
  }, [bannercontent.bannerimg.length, indicatorClicked, nextBanner]);
  useEffect(() => {
    runHeroCarousel();
    return () => clearInterval(carouselInterval.current);
  }, [runHeroCarousel]);

  const handleClick = (e, element, index) => {
    indicatorClicked = true;
    const banner = document.getElementById(element);
    if (!banner) return;
    if (banner.scrollIntoViewIfNeeded) {
      e.preventDefault();
      banner.scrollIntoViewIfNeeded();
    } else if (banner.scrollIntoView) {
      e.preventDefault();
      banner.scrollIntoView();
    }

    const indicatorContainer = document.getElementsByClassName(
      styles.carouselControl
    );
    const atags = indicatorContainer[0].children;
    atags[activeIndex.current].classList.remove(styles.active);
    activeIndex.current = index;
    atags[activeIndex.current].classList.add(styles.active);
    setTimeout(() => {
      indicatorClicked = false;
    }, 3000);
  };

  return (
    <section className={`col-12 text-white position-relative px-0`}>
      <div id="banner-carousel" className={`carousel d-flex`}>
        {bannercontent.bannerimg.map((x, index) => {
          return (
            <div
              id={`slide-${index + 1}`}
              className={`carouselItem w-100 position-relative`}
              key={index}
            >
              <div
                className={`${styles.carouselImage} w-100`}
                style={{
                  backgroundImage: `linear-gradient(to bottom right, rgba(0,0,0,0.6), transparent), 
                linear-gradient(to bottom left, rgba(0,0,0,0.6), transparent), url(${x.fluid.src})`,
                }}
              ></div>

              <div
                className={`${styles.captionContainer} h-100 abs-top-left d-flex flex-column justify-content-center align-items-center align-items-lg-start`}
              >
                <Picture
                  image={bannercontent.ngobannerlogo}
                  className={`${styles.captionNGOImage} mb-4`}
                />
                <div
                  className={`d-flex align-items-center flex-column-reverse flex-lg-row mb-4`}
                >
                  <h1
                    className={`${styles.captionNGOName} mb-0 mr-0 mr-lg-4 font-weight-bolder text-white`}
                  >
                    {bannercontent.ngoname}
                  </h1>
                  <span
                    className={`font-20 mb-3 mb-lg-0 text-04EB84 letterSpacing0`}
                  >
                    <img
                      src={signVerified}
                      className={`${styles.checkIcon} mr-1 mr-lg-2`}
                      alt="check icon"
                    />
                    Digital Daana Verified
                  </span>
                </div>
                <div
                  className={`font-18 text-B0C7D2 d-flex d-lg-block justify-content-center align-items-center flex-column flex-md-row flex-wrap`}
                >
                  <span className="text-center text-lg-left">
                    Established in {bannercontent.establishmentyear}
                  </span>
                  <span className={styles.captionNGOSeparator}>&bull;</span>
                  <span className="text-center text-lg-left">
                    Reg. No. {bannercontent.registrationnumber}
                  </span>
                  <span className={styles.captionNGOSeparator}>&bull;</span>
                  <span className="text-center text-lg-left">
                    Darpan ID {bannercontent.darpanid}
                  </span>
                </div>
                <div>
                  <button
                    className={`btn button-outline-8BC540 button-bg-translucent-dark
                ${styles.captionButton} font-16 fw-500 letter-spacing-019 text-uppercase mt-4`}
                  >
                    {bannercontent.buttontext}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {bannercontent.bannerimg.length > 1 && (
        <div
          className={`${styles.carouselControl} w-100 position-absolute text-center`}
        >
          {bannercontent.bannerimg.map((x, index) => {
            return (
              <a
                href={`#slide-${index + 1}`}
                key={index}
                onClick={e => {
                  handleClick(e, `slide-${index + 1}`, index);
                }}
                className={`${
                  index === activeIndex.current ? styles.active : ""
                } d-inline-block`}
              >
                <span
                  className={`${styles.controlDot} d-block mx-2 px-2 cursor-pointer`}
                ></span>
              </a>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default NgoHeroSection;
