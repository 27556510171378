import React from "react";

const NgoContactSection = ({ url, address }) => {
  return (
    <section className={`col-12 section-padding `}>
      <span className="pill-label pill-bg-gradient">contact</span>
      <h1 className="section-title text-black">Keep In Touch</h1>
      <div className="text-center font-22 letterSpacing0 text-637285">
        <p>{url}</p>
        <p className="mb-0">{address}</p>
      </div>
    </section>
  );
};

export default NgoContactSection;
