import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "../components/layout";
import NgoHeroSection from "../components/ngoHeroSection";
import NgoAboutSection from "../components/ngoAboutSection";
import NgoMissionSection from "../components/ngoMissionSection";
import NgoTrusteeSection from "../components/ngoTrusteeSection";
import NgoAwardsPartnersSection from "../components/ngoAwardsPartnersSection";
import NgoCertificatesSection from "../components/ngoCertificatesSection";
import NgoContactSection from "../components/ngoContactSection";
import LiveEdit from "../components/liveEdit";

function NgoDetails(props) {
  const node = props.data.contentfulPartnerNgo;
  const ngoSlug = `/ngo-details/${node.slug}`;
  const ngoAbsoluteURL = `${process.env.GATSBY_PUBLIC_URL}${ngoSlug}`;
  const ngoBannerImage = `https:${node.bannercontent.bannerimg[0].file.url}`;
  return (
    <>
      <Helmet>
        <meta
          name="og:image"
          property="og:image"
          content={`${ngoBannerImage}?w=430&h=495`}
        />
        <title>{node.title} | Metta Social</title>
        <meta name="twitter:title" content={node.title} />
        <meta name="og:title" property="og:title" content={node.title} />
        <meta property="og:type" name="og:type" content="website" />
        <meta name="description" content={node.seoDescription.seoDescription} />
        <meta
          property="og:description"
          name="og:description"
          content={node.seoDescription.seoDescription}
        />
        <meta
          name="twitter:description"
          content={node.seoDescription.seoDescription}
        />
        <meta property="og:url" name="og:url" content={ngoAbsoluteURL} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@metta_social" />
        <meta
          property="og:image"
          name="og:image"
          content={`${ngoBannerImage}?w=128&h=128`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${ngoBannerImage}?w=192&h=192`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${ngoBannerImage}?w=200&h=200`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${ngoBannerImage}?w=512&h=512`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${ngoBannerImage}?w=800&h=800`}
        />
        <meta
          name="image"
          property="og:image"
          content={`${ngoBannerImage}?w=1200&h=600`}
        />
        <meta
          name="og:image:alt"
          property="og:image:alt"
          content={node.title}
        />
        <meta name="twitter:image" content={`${ngoBannerImage}?w=128&h=128`} />
        {!process.env.GATSBY_ENV && (
          <script
            type="text/javascript"
            src="https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-609004268a9d389e"
            async
            defer
          ></script>
        )}
      </Helmet>

      <Layout>
        <NgoHeroSection bannercontent={node.bannercontent} />
        <NgoAboutSection
          ngoname={node.bannercontent.ngoname}
          description={node.description}
        />
        <NgoMissionSection
          Ngomissionimg={node.Ngomissionimg}
          mission={node.mission}
          vision={node.vision}
          displayBeneficiariesCount={node.displayBeneficiariesCount}
          beneficiariesCount={node.beneficiariesCount}
        />
        {node.trusteeImage && (
          <NgoTrusteeSection trusteeImage={node.trusteeImage} />
        )}
        {node.awards && (
          <NgoAwardsPartnersSection awards={node.awards} type="awards" />
        )}
        {node.displayCertificates && node.certificates && (
          <NgoCertificatesSection certificates={node.certificates} />
        )}
        {node.partners && (
          <NgoAwardsPartnersSection partners={node.partners} type="partners" />
        )}
        <NgoContactSection url={node.url} address={node.address} />
      </Layout>
      {process.env.GATSBY_ENV && <LiveEdit />}
    </>
  );
}
export default NgoDetails;
export const query = graphql`
  query($id: String!) {
    contentfulPartnerNgo(id: { eq: $id }) {
      id
      slug
      title
      seoDescription {
        seoDescription
      }
      description {
        description
      }
      url
      address
      bannercontent {
        buttontext
        ngoname
        establishmentyear
        registrationnumber
        darpanid
        bannerimg {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
          file {
            url
            fileName
          }
        }
        ngobannerlogo {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
          file {
            url
            fileName
          }
        }
      }
      mission
      vision
      displayBeneficiariesCount
      beneficiariesCount
      Ngomissionimg {
        file {
          url
          fileName
          contentType
        }
        fluid {
          src
          srcSet
          srcSetWebp
          srcWebp
          sizes
        }
      }
      awards {
        fluid {
          src
          srcSet
          srcSetWebp
          srcWebp
          sizes
        }
        file {
          url
          fileName
        }
      }
      displayCertificates
      certificates {
        title
        description
        file {
          url
          fileName
          contentType
        }
        fluid {
          src
          srcSet
          srcSetWebp
          srcWebp
          sizes
        }
      }
      logo {
        fluid {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
        }
        file {
          url
          fileName
        }
      }
      trusteeImage {
        title
        description
        fluid {
          src
          srcSet
          srcSetWebp
          srcWebp
          sizes
        }
        file {
          url
          fileName
        }
      }
      partners {
        title
        description
        fluid {
          src
          srcSet
          srcSetWebp
          srcWebp
          sizes
        }
        file {
          url
          fileName
        }
      }
    }
  }
`;
